import { template as template_1a8dde1b997b455185fb2e4676b5b0cc } from "@ember/template-compiler";
const SidebarSectionMessage = template_1a8dde1b997b455185fb2e4676b5b0cc(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
