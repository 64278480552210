import { template as template_9edf223a8ce14452be86b7aa1d53f3f1 } from "@ember/template-compiler";
const FKLabel = template_9edf223a8ce14452be86b7aa1d53f3f1(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
